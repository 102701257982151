import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  children,
  description,
  lang,
  meta,
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            title
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const titleTemplate = title ? `${site.siteMetadata.title} | ${title}` : site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleTemplate}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: titleTemplate,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: '/open-graph.png',
        },
        {
          property: 'og:height',
          content: 630,
        },
        {
          property: 'og:width',
          content: 1200,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: site.siteUrl,
        },
        {
          property: 'twitter:title',
          content: titleTemplate,
        },
        {
          property: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'twitter:image',
          content: '/twitter-card.png',
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
      ].concat(meta)}
    >
      {children}
    </Helmet>
  );
};

SEO.defaultProps = {
  children: null,
  lang: 'en',
  meta: [],
  description: null,
  title: null,
};

SEO.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
