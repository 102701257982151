import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import './link.css';

const getButtonLinkClass = (color, className) => {
  const classes = ['page__button-link'];

  if (className) {
    classes.push(className);
  }

  if (color === 'primary') {
    classes.push('page__button-link_primary');
  } else {
    classes.push('page__button-link_default');
  }

  return classes.join(' ');
};

const getLinkClass = (className) => {
  const classes = ['page__link'];

  if (className) {
    classes.push(className);
  }

  return classes.join(' ');
};

const Link = ({
  className,
  children,
  color,
  direction,
  linkType,
  to,
  transition,
  ...props
}) => {
  if (linkType === 'plain') {
    return (
      <a
        className={getLinkClass(className)}
        href={to}
        {...props}
      >
        {children}
      </a>
    );
  } if (linkType === 'button' && !to.startsWith('/')) {
    return (
      <span className={getButtonLinkClass(color, className)}>
        <a
          to={to}
          {...props}
        >
          {children}
        </a>
      </span>
    );
  } if (transition === false) {
    return (
      <span className={getButtonLinkClass(color, className)}>
        <GatsbyLink
          to={to}
          {...props}
        >
          {children}
        </GatsbyLink>
      </span>
    );
  }
  return (
    <span className={getButtonLinkClass(color, className)}>
      <AniLink
        direction={direction}
        entryOffset={100}
        to={to}
        {...props}
      >
        {children}
      </AniLink>
    </span>
  );
};

Link.defaultProps = {
  className: '',
  color: 'default',
  direction: 'left',
  linkType: 'button',
  to: '/',
  transition: true,
};

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  direction: PropTypes.string,
  linkType: PropTypes.string,
  to: PropTypes.string,
  transition: PropTypes.bool,
};

export default Link;
